import React from 'react';
import { Box } from '@mui/material';
import Sidebar from '../Sidebar';
import Fab from '@mui/material/Fab';
import MenuIcon from '@mui/icons-material/Menu';

const LinkSidebar = (props) => {

  const {children, ...next} = props;

  const [collapsed, setCollapsed] = React.useState(true);
  const [toggled, setToggled] = React.useState(false);

  const onClick = () => {
      setToggled(!toggled)
  }

  const handleCollapsedChange = (checked) => {
      setCollapsed(checked);
  };

  const handleToggleSidebar = (value) => {
      setToggled(value);
  };

  const fabStyle = {
      padding: 1
      ,display: 'none'
      ,"@media (max-width: 767.98px)" : {
          display: toggled ? 'none' : 'block'
      }
      ,position: 'absolute'
      ,top: '11px'
      ,left: '9px'
      ,zIndex: 99999
  };

  return (
      <Box
          style={{
              display: 'flex'
              ,flexDirection: 'row'
              ,justifyContent: 'flex-start'
              ,alignItems:'flex-start'
              ,height: `calc(100vh - ${props.minusHeight})`
              ,width: '100%'
              ,position: 'relative'
              ,overflow: 'hidden'
          }}
      >
          <Fab
              variant="circular"
              size="small"
              aria-label="manu"
              onClick={onClick}
              sx={fabStyle}
          >
              <MenuIcon/>
          </Fab>
          <Sidebar
              collapsed={collapsed}
              toggled={toggled}
              handleToggleSidebar={handleToggleSidebar}
              handleCollapsedChange={handleCollapsedChange}
              {...next}
          />
          {children}
      </Box>
  )
}

LinkSidebar.defaultProps = {
    minusHeight: '0px'
    ,overflowY: 'hidden'
    ,overflowX: 'hidden'
}

export default LinkSidebar;
