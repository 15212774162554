import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import {Link} from 'react-router-dom';
import {
  MenuItem
} from 'react-pro-sidebar';
import useStyleSidebar from '../../hooks/useStyleSidebar';

const SubitemLink = (props) => {

  const classes = useStyleSidebar();

  const onIconClick = (e, options) => {
      if (options.disabled || options.hidden) {
          e.preventDefault()
          e.stopPropagation()
          return false;
      }
  }

  return (
    <Tooltip key={'ttp' + props.path}
      title={props.label}
      placement="right" arrow
    >
      <MenuItem
          className={classes.item}
          key={'subm' + props.path}
          style={{
            background: props.path === props.value ? '#614e0a' : '#353535'
          }}
          prefix={props.icon}
          popperarrow={true}
          disabled={panel.disabled}
          onClick={() => true}>
              <Link
                  key={`lnk-Sub-${props.path}`}
                  to={props.path}
                  className={classes.SidebarLink}
                  onClick={(e) => onIconClick(e, props)}
              >
                  {props.label}
              </Link>
      </MenuItem>
    </Tooltip>
  )
}

export default SubitemLink;
