import React from 'react';
import LinkSidebar from '../LinkSidebar';
import Main from '../Main';

const LinkSidebarPanel = (props) => {

    return (
        <LinkSidebar {...props} >
          <Main {...props} />
        </LinkSidebar>
    );
};

export default LinkSidebarPanel;
