import React from 'react';
import RouteSwitchPanel from '@sistemas.mundo/route-switch-panel';
import ContainerPanel from '@sistemas.mundo/container-panel';

const RouteMain = (props) => {

    const {
        image
        ,onChangePanel
        ,onChangeKeyRef
        ,handleToggleSidebar
        ,itemsPanels
        ,menuItems
        ,defaultKeyRef
        ,rtl
        ,collapsed
        ,toggled
        ,...next
    } = props;

    const getMenuItems = () => {
      const items = [...menuItems];

      return items.map((item) => {
        let {overflowY, ...next} = item || {};
        next.overflowY = overflowY ?? 'auto';
        return next;
      });

    }

    return (
        <ContainerPanel
            alignItems='stretch'
            justifyContent='flex-start'
            style={{position: 'relative'}}
            minusHeight={props.minusHeight}
            overflowY={props.overflowY}
            overflowX={props.overflowX}
        >
          <RouteSwitchPanel
              {...next}
              itemsPanels={getMenuItems()}
          />
        </ContainerPanel>
    );
};

RouteMain.defaultProps = {
    handleToggleSidebar: () => true
    ,itemsPanels: []
    ,defaultKeyRef: null
    ,minusHeight: '0px'
    ,overflowY: 'hidden'
    ,overflowX: 'hidden'
}

export default RouteMain;
