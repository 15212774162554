import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import {Link} from 'react-router-dom';
import {
  MenuItem
} from 'react-pro-sidebar';
import useStyleSidebar from '../../hooks/useStyleSidebar';

const ItemLink = (props) => {

  const classes = useStyleSidebar();

  const onIconClick = (e, options) => {
      if (options.disabled || options.hidden) {
          e.preventDefault()
          e.stopPropagation()
          return false;
      }
  }

  return (
    <Tooltip key={'tlp' + props.label} title={props.tooltip} placement="right" arrow>
      <MenuItem
          key={'mnit' + props.path}
          style={{
            background: props.path === props.value ? '#614e0a' : '#353535'
            ,color: props.disabled === true ? '#5c5a5a' : 'inherit'
          }}
          icon={props.icon}
          disabled={props.disabled}
          onClick={() => false}
        >
          {
            props.disabled ? props.label :
            <Link
              key={`lnk-${props.path}`}
              to={props.path}
              className={classes.SidebarLink}
              onClick={(e) => onIconClick(e, props)}
            >
              {props.label}
            </Link>
          }
      </MenuItem>
    </Tooltip>
  )
}

export default ItemLink;
