import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  SubMenu
} from 'react-pro-sidebar';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import 'react-pro-sidebar/dist/css/styles.css';
import usePathValue from '../../hooks/usePathValue';
import useStyleSidebar from '../../hooks/useStyleSidebar';
import ItemLink from '../ItemLink';
import SubitemLink from '../SubitemLink';

const Sidebar = (props) => {

    const {staticContext, ...rest} = props;

    const classes = useStyleSidebar();

    const [collapsed, setCollapsed] = React.useState(true);
    const [toggled, setToggled] = React.useState(false);
    const [value, basename] = usePathValue(props, props.menuItems);

    React.useEffect(() => {
        setCollapsed(props.collapsed);
    }, [props.collapsed]);

    React.useEffect(() => {
        setToggled(props.toggled);
    }, [props.toggled]);


    const getTooltip = (title, tooltip) => tooltip ? <React.Fragment><div><b>{title}</b></div><div>{tooltip}</div></React.Fragment> : title

    const getItemHeader = () => {
        return (
          <Menu iconShape="circle">
            <MenuItem
                icon={<CompareArrowsIcon />}
                onClick={() => handleCollapsedChange(!collapsed)}
                className={classes.SidebarHeader}
            >
                {!collapsed ? props.moduloName : ''}
            </MenuItem>
        </Menu>
        )
    }

    const getItem = (panel, index) => {

        if (panel.hidden){
          return;
        }
        return (
          <ItemLink {...panel} tooltip={getTooltip(panel.label, panel.tooltip)} value={value} />
        )
    }

    const getSubItem = (spanel) => {

      return (
        <SubitemLink {...spanel} tooltip={getTooltip(panel.label, panel.tooltip)} value={value}/>
      )
    }

    const getSubItems = (subitems, panel, index) => {

        return (
            <SubMenu
                key={index}
                icon={panel.icon}
                title={panel.label}
            >
                {subitems.map((spanel, sindex) => {
                    return getSubItem(spanel, sindex);
                })}
            </SubMenu>
        )
    }

    const getItemsMenu = () => {

        const menuItems = props.menuItems.map((panel, index) => {
            const subitems = props.menuItems.filter(item => item.parentPath=== panel.path)
            if (subitems.length === 0){
                return (!panel.parentPath) ? getItem(panel, index) : '';
            }
            else{
                return getSubItems(subitems, panel, index);
            }
        })

        return (
            <Menu iconShape="none">
                {menuItems}
            </Menu>
        )
    }

    const getFooter = () => {
        return (
            <div
                className="sidebar-btn-wrapper"
                style={{padding: '15px 15px'}}
            >
                {props.footer}
            </div>
        )
    }

    const handleCollapsedChange = (checked) => {
        setCollapsed(checked);
    };

    return (
        <Router basename={props.basename}>
            <ProSidebar
                image={false}
                rtl={false}
                collapsed={collapsed}
                toggled={toggled}
                breakPoint="md"
                onToggle={props.handleToggleSidebar}
                width={215}
            >
                <SidebarHeader>
                    {getItemHeader()}
                </SidebarHeader>
                <SidebarContent>
                    {getItemsMenu()}
                </SidebarContent>
                <SidebarFooter>
                    {getFooter()}
                </SidebarFooter>
            </ProSidebar>
        </Router>
    )
}

Sidebar.defaultProps = {
    menuItems: []
    ,moduloName: 'Módulo'
    ,image: null
    ,collapsed: false
    ,toggled: false
    ,onChangeKeyRef: () => true
    ,handleCollapsedChange: () => true
    ,defaultPath: null
    ,basename: '/'
    ,onIconClick: (newKeyRef) => true
}

export default Sidebar;
