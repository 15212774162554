import React from 'react';
import RouteSwitchPanel from '@sistemas.mundo/route-switch-panel';
import ContainerPanel from '@sistemas.mundo/container-panel';
import { Paper } from '@mui/material';

const MainPaper = (props) => {

    const {
        image
        ,onChangePanel
        ,onChangeKeyRef
        ,handleToggleSidebar
        ,itemsPanels
        ,menuItems
        ,defaultKeyRef
        ,rtl
        ,collapsed
        ,toggled
        ,...next
    } = props;

    const getMenuItems = () => {
      const items = [...menuItems];

      return items.map((item) => {
        let {overflowY, ...next} = item || {};
        next.overflowY = overflowY ?? 'auto';
        return next;
      });

    }

    const style = {height: `calc(100vh - ${props.minusHeight})`, ...props.sx}

    return (
      <Paper
        style={style}
        elevation={2}
      >
        <ContainerPanel
          alignItems='stretch'
          justifyContent='flex-start'
          style={{position: 'relative'}}
          minusHeight={props.minusHeight}
          overflowY={props.overflowY}
          overflowX={props.overflowX}
        >
          <RouteSwitchPanel
            {...next}
            itemsPanels={getMenuItems()}
          />
        </ContainerPanel>
      </Paper>
    );
};

MainPaper.defaultProps = {
    handleToggleSidebar: () => true
    ,onChangeKeyRef: () => true
    ,itemsPanels: []
    ,defaultKeyRef: null
    ,minusHeight: '0px'
    ,overflowY: 'hidden'
    ,overflowX: 'hidden'
    ,sx: {}
}

export default MainPaper;
