import React from 'react';
import { matchPath } from "react-router";

const usePathValue = (props, items) => {

  const [value, setValue] = React.useState(null);
  const [basename, setBasename] = React.useState(null);

  React.useEffect(() => {
    if (props.basename){
      setBasename(props.basename === '/' ? '' : props.basename);
    }
  }, [props.basename]);

  React.useEffect(() => {

    if  (basename === null) return;

    const matchBase = matchPath(props.location.pathname, {
      path: `${props.basename}`
      ,exact: false
      ,strict: false
    });

    if (matchBase?.isExact === true){
      if (!value && props.defaultPath){
        if(items.filter((link) => link.path === props.defaultPath && link.hidden !== true && link.disabled !== true).length){
          setValue(`${props.defaultPath}`);
          props.history.push(`${basename}${props.defaultPath}`)
        }
      }
    }
    else if(matchBase){
      const matchRoot = matchPath(props.location.pathname, {
        path: `${basename}/:path`
        ,exact: false
        ,strict: false
      });

      if (matchRoot){
        if (matchRoot.params.path){

          if(items.filter((link) => link.path === `/${matchRoot.params.path}` && link.hidden !== true && link.disabled !== true).length){
            setValue(`/${matchRoot.params.path}`);
          }
        }
      }
    }
  })

  return [value, basename];
}

export default usePathValue;
