import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles(() => ({
  SidebarHeader: {
      padding: '2px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: 14,
      letterSpacing: '1px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      textAlign: 'center',
  }
  ,SidebarLink:{
      textDecoration: 'none'
      ,color: 'inherit'
    }
}));

const useStyleSidebar = props => {
    const classes = useStyles(props);

    return classes;
}

export default useStyleSidebar;
