import React from 'react';
import LinkSidebar from '../LinkSidebar';
import MainPaper from '../MainPaper';

const LinkSidebarPaper = (props) => {

    return (
        <LinkSidebar {...props} >
          <MainPaper {...props} />
        </LinkSidebar>
    );
};

export default LinkSidebarPaper;
